###
         oo                            dP dP
                                       88 88
dP   .dP dP .d8888b. dP    dP .d8888b. 88 88 .d8888b. dP.  .dP
88   d8' 88 Y8ooooo. 88    88 88'  `88 88 88 88'  `88  `8bd8'
88 .88'  88       88 88.  .88 88.  .88 88 88 88.  .88  .d88b.
8888P'   dP `88888P' `88888P' `88888P8 dP dP `88888P8 dP'  `dP
ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

A smart and efficient parallax plugin by Alex Grozav
from Pixevil built to make the web a better place.

@plugin  	Visuallax
@author 	Alex Grozav
@website  http://pixevil.com
@version 	1.0
@license 	Commercial
###

(($, window, document) ->
  "use strict"

  # @Visuallax
  $.visuallax = (element, options) ->
    # This is where we place our default values.
    _defaults =
      orientation: 'vertical'
      mode: 'default' # 'to-middle', 'from-middle', 'default'
      overflow: false # Take parent overflowing into account
      translate_x: false
      translate_y: false
      translate_z: false
      rotate_x: false
      rotate_y: false
      rotate_z: false
      opacity: false
      opacity_style: 'default'
      scale: false
      scale_style: 'default'
      parent: false # Parent element, by default set as direct parent
      source: false # Element from which to gather size data
      reset: false # Reset animation when conditions not met
      responsive: # Refers to the range maximum value
        xs: [0, []]
        sm: [768, []]
        md: [992, []]
        lg: [1200, []]
        xlg: [1840, []]

    debug: true

    @_defaults = _defaults

    @settings = $.extend {}, _defaults, options

    @element = $ element
    @source = if @settings.source then @settings.source else @element
    @parent = if @settings.parent then @settings.parent else @element.parent()

    @window = $ window


    # Get all child elements which have revelate
    # events attached through data attributes
    #
    @initialize = =>
      @get_data()

      # Get window sizes
      @window_width = @window.width()
      @window_height = @window.height()

      # Bind resize and scroll
      @bind_resize()
      @bind_scroll()

      # Set current element positions
      @set_responsive_size()
      @set_size()
      @set_position()

      # Parallax to the current position
      @parallax @window.scrollTop()

      return

    # Initialize element cache with its data settings
    #
    @get_data = =>
      @cache = $.extend {}, @settings

      @cache.xs = @cache.responsive.xs[1]
      @cache.sm = @cache.responsive.sm[1]
      @cache.md = @cache.responsive.md[1]
      @cache.lg = @cache.responsive.lg[1]
      @cache.xlg = @cache.responsive.xlg[1]

      data = @element.attr 'data-visuallax'
      if data?
        data = data.split(',')
        $.each data, (index, string) =>
          string = $.trim string
          string = string.split /\s+/
          string = string.map (n) ->
            if n is 'false' then false else n.toLowerCase()

          switch string[0]
            when 'translate', 'move'
              if string[1] == 'x' or string[1] == 'y' or string[1] == 'z'
                @cache["translate_#{string[1]}"] = parseFloat string[2]
              else
                @cache.translate_y = parseFloat string[1]

            when 'rotate'
              if string[1] == 'x' or string[1] == 'y' or string[1] == 'z'
                @cache["rotate_#{string[1]}"] = parseFloat string[2]
              else
                @cache.rotate_z = parseFloat string[1]

            when 'opacity', 'fade'
              i = 1
              if string[i] is 'simple' or string[i] is 'default'
                @cache.opacity_style = string[i++]
              @cache.opacity = parseFloat string[i]

            when 'scale'
              i = 1
              if string[i] is 'simple' or string[i] is 'default'
                @cache.scale_style = string[i++]
              @cache.scale = parseFloat string[i]

            when 'xs', 'sm', 'md', 'lg', 'xlg'
              i = 0
              while string[++i]
                @cache[string[0]].push string[i]
            else
              @cache[string[0]] = string[1]
          return
      return


    # Parallax images when in fluid layout mode
    #
    # @param position [Fixnum] Current scrolling position
    #
    @parallax = (position) =>
      translate_y = parseFloat $.Velocity.hook(@element, 'translateY')
      formula = (position + @window_height / 2 - (@top + @bottom) / 2)

      # Only parallax until the middle point or start from the middle point
      if (@cache.mode is 'to-middle' and formula >= 0) || (@cache.mode is 'from-middle' and formula <= 0)
        @reset() if @settings.reset
        return

      # Do not overflow the parent height when parallaxing
      if @cache.overflow && @element_height >= @parent_height && (@top >= position && @bottom <= position + @window_height)
        @reset() if @settings.reset
        return
      #( @top >= position && @bottom <= position + @window_height) ||
      #( @top <= position && @bottom >= position + @window_height))

      # Only parallax when the element is in the view
      if (position + @window_height > @top + translate_y) and (position < @bottom + translate_y)
        # Apply the Hardware Acceleration trick
        if @cache.translate_z and $.inArray("translate_z", @cache[@current_responsive_size]) is -1
          translate_z_factor = "#{formula * @cache.translate_z}px"
          $.Velocity.hook @element, 'translateZ', translate_z_factor
        else
          $.Velocity.hook @element, 'translateZ', 0

        if @cache.translate_x and $.inArray("translate_x", @cache[@current_responsive_size]) is -1
          translate_x_factor = "#{formula * @cache.translate_x}px"
          $.Velocity.hook @element, 'translateX', translate_x_factor

        if @cache.translate_y and $.inArray("translate_y", @cache[@current_responsive_size]) is -1
          translate_y_factor = "#{formula * @cache.translate_y}px"
          $.Velocity.hook @element, 'translateY', translate_y_factor

        if @cache.rotate_x and $.inArray("rotate_x", @cache[@current_responsive_size]) is -1
          rotate_x_factor = "#{formula * @cache.rotate_x}deg"
          $.Velocity.hook @element, 'rotateX', rotate_x_factor

        if @cache.rotate_y and $.inArray("rotate_y", @cache[@current_responsive_size]) is -1
          rotate_y_factor = "#{formula * @cache.rotate_y}deg"
          $.Velocity.hook @element, 'rotateY', rotate_y_factor

        if @cache.rotate_z and $.inArray("rotate_z", @cache[@current_responsive_size]) is -1
          rotate_z_factor = "#{formula * @cache.rotate_z}deg"
          $.Velocity.hook @element, 'rotateZ', rotate_z_factor

        if @cache.scale and $.inArray("scale", @cache[@current_responsive_size]) is -1
          if @cache.scale_style is 'default'
            scale_factor = "#{1 + formula / 100 * @cache.scale}"
          else
            scale_factor = "#{1 - Math.abs (formula / 100 * @cache.scale)}"
          $.Velocity.hook @element, 'scale', scale_factor

        if @cache.opacity and $.inArray("opacity", @cache[@current_responsive_size]) is -1
          if @cache.opacity_style is 'default'
            opacity_factor = "#{1 - Math.abs (formula / 100 * @cache.opacity)}"
          else
            opacity_factor = "#{1 + formula / 100 * @cache.opacity}"
          $.Velocity.hook @element, 'opacity', opacity_factor
      else if @settings.reset
        @reset()

      return

    # Reset all parameters to the default values
    #
    @reset = =>
      $.Velocity.hook @element, 'translateX', 0 if @cache.translate_x
      $.Velocity.hook @element, 'translateY', 0 if @cache.translate_y
      $.Velocity.hook @element, 'rotateX', 0 if @cache.rotate_x
      $.Velocity.hook @element, 'rotateY', 0 if @cache.rotate_y
      $.Velocity.hook @element, 'rotateZ', 0 if @cache.rotate_z
      $.Velocity.hook @element, 'scale', 1 if @cache.scale
      $.Velocity.hook @element, 'opacity', 1 if @cache.opacity
      return

    # Set slider top and bottom positioning on the page
    #
    @set_position = =>
      @top = @source.offset().top
      @bottom = @top + @element_height

      @left = @source.offset().left
      @right = @left + @element_width

      @parent_top = @parent.offset().top
      @parent_bottom = @parent_top + @parent_height

      @parent_left = @parent.offset().left
      @parent_right = @parent_left + @parent_width

      return

    # Set element and slider size
    #
    @set_size = =>
      @parent_width = @parent.outerWidth(true)
      @parent_height = @parent.outerHeight(true)

      @element_width = @source.outerWidth(true)
      @element_height = @source.outerHeight(true)

      return

    # Set current responsive range parameter as xs,sm,md or lg
    #
    @set_responsive_size = =>
      if @window_width >= @settings.responsive.xlg[0]
        @current_responsive_size = 'xlg'
      else if @window_width >= @settings.responsive.lg[0]
        @current_responsive_size = 'lg'
      else if @window_width >= @settings.responsive.md[0]
        @current_responsive_size = 'md'
      else if @window_width >= @settings.responsive.sm[0]
        @current_responsive_size = 'sm'
      else
        @current_responsive_size = 'xs'

      return

    # Binds the slider window resize event to cache current window
    # width and height and to set the layout up
    #
    @bind_resize = =>
      @window.resize =>
        @window_width = @window.width()
        @window_height = @window.height()

        @reset()
        @parallax @window.scrollTop()
        @set_responsive_size()
        @set_size()
        @set_position()

        return
      return

    # Bind the window scroll event to fade content on scroll down
    #
    @bind_scroll = =>
      @window.on 'scroll', =>
        @set_position()
        @parallax @window.scrollTop()
        return
      return

    @initialize()

  # Lightweight plugin wrapper that prevents multiple instantiations.
  #
  $.fn.visuallax = (opts) ->
    @each (index, element) ->
      unless $.data element, "visuallax"
        $.data element, "visuallax", new $.visuallax element, opts

) window.jQuery, window, document
